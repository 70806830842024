import React from "react";
import Layout from "../components/global/layout/layout";
import Seo from "../components/global/seo/seo";
import Form from "../components/ui/form/form";

const Contact = () => {
  const initialForm = {
    name: {
      name: "Name",
      elementType: "input",
      value: "",
      valid: false,
      touched: false,
      labelConfig: {
        required: true,
        text: "Name",
      },
      elementConfig: {
        type: "text",
        placeholder: "",
      },
      classConfig: {
        classes: [],
      },
      validationConfig: {
        required: true,
        errorText: "Please enter your name",
      },
    },
    email: {
      name: "Email",
      elementType: "input",
      value: "",
      valid: false,
      touched: false,
      labelConfig: {
        required: true,
        text: "Email",
      },
      elementConfig: {
        type: "email",
        placeholder: "",
      },
      classConfig: {
        classes: [],
      },
      validationConfig: {
        required: true,
        isEmail: true,
        errorText: "Please enter a valid email address",
      },
    },
    subject: {
      name: "Subject",
      elementType: "input",
      value: "",
      valid: false,
      touched: false,
      labelConfig: {
        required: true,
        text: "Subject",
      },
      elementConfig: {
        type: "text",
        placeholder: "",
      },
      classConfig: {
        classes: [],
      },
      validationConfig: {
        required: true,
        errorText: "Please enter a subject",
      },
    },
    message: {
      name: "Message",
      elementType: "textarea",
      value: "",
      valid: false,
      touched: false,
      labelConfig: {
        required: true,
        text: "Message",
      },
      elementConfig: {
        rows: 3,
        placeholder: "",
      },
      classConfig: {
        classes: ["no-resize", "full-border"],
      },
      validationConfig: {
        required: true,
        errorText: "Please enter your message",
      },
    },
  };

  return (
    <Layout>
      <Seo title="Contact" />
      <div className="padded">
        <h1 className="page-title">Contact</h1>
        <Form
          name={"Contact Form"}
          initialForm={initialForm}
          className={"form contact-form"}
          dataNetlify={true}
        ></Form>
      </div>
    </Layout>
  );
};

export default Contact;
